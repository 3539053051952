<template>
    
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation :title="$t('message.login_title')" icon="" />

    <div class="loginContainer">
        <div class="row">

            <!-- <div class="col-12 text-danger">
                <div>{{ $t('message.support_title_desc') }}</div>
            </div> -->

            <!-- Left column - Login -->
            <div v-if="showUIUserPending === true"  class="col-12">
                <div class="border shadow-sm loginBox">
                    <div class="row">
                        <div class="col-12 col-lg-3 mb-2">
                            <fa icon="hourglass-half" style="font-size: 120px" />
                        </div>
                        <div class="col-12 col-lg-9 mb-2">
                            <div class="mb-2">{{ $t('message.login_desc_inactive_1') }}</div>
                            <div class="mb-2">{{ $t('message.login_desc_inactive_2') }}<span class="isLink" @click="showResend"><u>{{ $t('message.all_lbl_here') }}</u></span>{{ $t('message.all_punc_fullstop') }}</div>
                            <div class="mb-2">{{ $t('message.login_desc_inactive_3') }}</div>
                            <div>{{ $t('message.login_desc_inactive_4a') }}<a href="mailto:wecare@signon.my"><u>wecare@signon.my</u></a>{{ $t('message.login_desc_inactive_4b') }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showUIUserPending === false" class="col-12 col-lg-7 d-grid">
                <div v-if="validSession === true" class="border shadow-sm loginBox">
                    <div class="row p-0">
                        <div class="col-12">
                            <div class="col-12 mb-3">
                                {{ $t('message.login_desc_username_confirm_1') }}
                                <span>
                                    <span v-if="user.fullName" class="fw-bold">{{user.fullName}}</span>
                                    <span v-if="user.displayName" class="text-grey"><fa icon="at" class="mx-1"/>{{user.displayName}}</span>
                                    <span> ({{user.userId}})</span>
                                </span>{{ $t('message.all_punc_fullstop') }}
                            </div>
                            <div class="col-12 mb-3">{{ $t('message.login_desc_username_confirm_2') }}<span class="text-primary isLink" @click="$router.push({ path: '/dashboard' })">{{ $t('message.all_lbl_here') }}</span>{{ $t('message.all_punc_fullstop') }}</div>
                            <div class="col-12 mb-3">{{ $t('message.login_desc_username_confirm_3a') }}<span class="text-primary isLink" @click="logout">{{ $t('message.all_lbl_here') }}</span>{{ $t('message.login_desc_username_confirm_3b') }}</div>
                            <div class="col-12 mb-3">
                                {{ $t('message.login_desc_username_confirm_4a') }}<span class="text-primary isLink" @click="LogoutLogin">{{ $t('message.all_lbl_here') }}</span>{{ $t('message.login_desc_username_confirm_4b') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="validSession === false" class="border shadow-sm loginBox">
                    <div class="row p-0" v-if="(stage === 1 || stage === 2) && showUIUserPending === false">
                        <!-- ORIG
                        <div v-if="errorCode === 10014" class="col-12 bg-warning">
                            <div v-if="curLoginMethod === 'system'" class="my-4">
                                <div class="mb-2">The email address <strong>{{ username }}</strong> is already registered with Single Sign-On (SSO).</div>
                                <div>Please log in using Single Sign-On (SSO) via Google or change the login method to manual local login.</div>
                            </div>
                            <div v-if="curLoginMethod === 'oauth'" class="my-4">
                                <div class="mb-2">The email address <strong>{{ username }}</strong> is already registered for manual local login.</div>
                                <div>Please log in using manual local method or change the login method to Single Sign-On (SSO) via Google.</div>
                            </div>
                        </div>

                        <div v-if="errorCode !== 10014 && showForgotPassword === false" class="col-12 mb-3">
                            A simple, fast and secure way to sign documents online.
                        </div>
                        <div v-else-if="errorCode !== 10014 && showForgotPassword === true" class="col-12 mb-2">
                            Please try to re-key in your password or you may request to reset your password.
                        </div> -->

                        <div v-if="errorCode === 'invalid_open_id'" class="col-12">
                            <div v-if="curLoginMethod === 'system'" class="my-4">
                                <div class="mb-2">{{ $t('message.login_desc_invalid_openid_1a') }}<strong>{{ username }}</strong>{{ $t('message.login_desc_invalid_openid_1b') }}</div>
                                <div>{{ $t('message.login_desc_invalid_openid_2') }}<!--  or change the login method to manual local login --></div>
                            </div>
                            <div v-if="curLoginMethod === 'oauth'" class="my-4">
                                <div class="mb-2">{{ $t('message.login_desc_reg_local_1a') }}<strong>{{ username }}</strong>{{ $t('message.login_desc_reg_local_1b') }}</div>
                                <div>{{ $t('message.login_desc_reg_local_2') }}<!-- or change the login method to Single Sign-On (SSO) via Google. --></div>
                            </div>
                        </div>
                        <div v-else class="col-12 mb-3">
                            <div v-if="errorCode === 'login_attempted_exceeded'">
                                <div class="mb-2">{{ $t('message.login_desc_exceed_max_1') }}</div>
                                <div>{{ $t('message.login_desc_exceed_max_2') }}</div>
                            </div>
                            <div v-else-if="errorCode === 'possible_attack'">
                                <div class="mb-2">{{ $t('message.login_desc_attack_1') }}</div>
                                <div>{{ $t('message.login_desc_attack_2') }}</div>
                            </div>
                            <div v-else-if="errorCode === 'invalid_password'">
                                {{ $t('message.login_desc_invalid_pwd') }}
                            </div>
                            <div v-else>
                                {{ $t('message.login_title_desc') }}
                            </div>
                        </div>
                        
                    </div>

                    <div v-if="stage === 1 && showUIUserPending === false" class="row p-0">
                        <div class="col-12 pb-4">
                            <input v-model="username" type="text" class="form-control" @keyup.enter="nextStage" placeholder="Username" @focus="true" :autofocus="isUsernameFocus === true && curLoginMethod === 'oauth'" maxlength="100">
                        </div>

                        <div class="col-12">
                            <!-- ORIG 
                            <button v-if="errorCode !== 10014" class="btn btn-secondary me-2" @click="nextStage" autofocus>Login</button>
                            <button v-if="errorCode === 10014 && curLoginMethod === 'system'" class="btn btn-outline-warning me-2 float-start" @click="redirectLogin" autofocus>Back</button>
                            <button v-if="errorCode === 10014 && curLoginMethod === 'system'" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#mdlChangeToManual" autofocus :disabled="username === '' || username === null">Change to Manual Local Login</button>

                            <button v-if="errorCode === 10014 && curLoginMethod === 'oauth'" class="btn btn-outline-warning float-start" data-bs-toggle="modal" data-bs-target="#mdlChangeToSSO" autofocus :disabled="username === '' || username === null">Change to SSO</button>
                            <button v-if="errorCode === 10014 && curLoginMethod === 'oauth'" class="btn btn-warning" @click="nextStage" autofocus>Proceed Manual Local Login</button> -->

                            <div v-if="errorCode === 'invalid_open_id'" class="text-end">
                                <button v-if="curLoginMethod === 'system'" class="btn btn-secondary me-2 float-start" @click="redirectLogin" autofocus>{{ $t('message.all_btn_back') }}</button>
                                <button v-if="curLoginMethod === 'system'" class="btn btn-info text-white me-2 float-end" @click="loginGoogle">
                                    <i class="fa-brands fa-google fa-xl me-2"></i>{{ $t('message.login_btn_login_google') }}
                                </button>
                                <!-- <button v-if="curLoginMethod === 'system'" class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#mdlChangeToManual" autofocus :disabled="username === '' || username === null">Change to Manual Local Login</button> -->
                                <!-- <button v-if="curLoginMethod === 'oauth'" class="btn btn-outline-warning float-start" data-bs-toggle="modal" data-bs-target="#mdlChangeToSSO" autofocus :disabled="username === '' || username === null">Change to SSO</button> -->
                                <button v-if="curLoginMethod === 'oauth'" class="btn btn-info text-white" @click="nextStage" autofocus>{{ $t('message.login_btn_login_local') }}</button>
                            </div>
                            <div v-else-if="errorCode === 'login_attempted_exceeded'">
                                <button class="btn btn-secondary" @click="redirectForgotPassword()">{{ $t('message.all_btn_reset_pwd') }}</button>
                                <button class="btn btn-info text-white me-2 float-end" @click="nextStage" autofocus>{{ $t('message.all_btn_login') }}</button>
                            </div>
                            <div v-else class="text-end">
                                <button class="btn btn-info text-white me-2" @click="nextStage" autofocus>{{ $t('message.all_btn_login') }}</button>
                            </div>

                        </div>
                        
                        <!-- oauth login -->
                        <div v-if="errorCode !== 'invalid_open_id'" class="col-12 my-4">
                            <table width="100%">
                                <tr>
                                    <td width="45%"><hr /></td>
                                    <td class="text-center">{{ $t('message.login_desc_or') }}</td>
                                    <td width="45%"><hr /></td>
                                </tr>
                            </table>
                        </div>

                        <!-- <div class="col text-center">
                            <button class="btn btn-outline-secondary" @click="loginGoogle">
                                <i class="fa-brands fa-google"></i> Sign in with Google
                            </button>
                        </div> -->
                        
                        <div v-if="errorCode !== 'invalid_open_id'" class="col text-center">
                        <!-- <div class="col text-center"> -->
                            <span class="text-info isLink" @click="loginGoogle" :class="isOauthFocus === true ? 'text-info' : ''">
                                <Popper class="popperDark" arrow hover :content="$t('message.login_btn_login_google')">
                                    <i class="fa-brands fa-google fa-2xl"></i>
                                </Popper>
                            </span>
                        </div>
                        
                        <!-- 
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Facebook">
                                <fa :icon="['fab', 'facebook']" style="font-size: 28px" />
                            </Popper>
                            </div>
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Yahoo">
                                <fa :icon="['fab', 'yahoo']" style="font-size: 28px" />
                            </Popper>
                        </div>
                        <div class="col text-center">
                            <Popper class="popperDark" arrow hover content="Microsoft">
                                <fa :icon="['fab', 'microsoft']" style="font-size: 28px" />
                            </Popper>
                        </div> -->
                        <!-- oauth login end-->
              
                    </div>

                    <div v-if="stage === 2 && showUIUserPending === false" class="row">
                        <div class="col-12 pb-3">
                            <input v-model="username" type="text" class="form-control mb-2" readonly />
                            <input v-model="password" type="password" class="form-control" @keyup.enter="login" :placeholder="$t('message.all_lbl_pwd')" @focus="true" ref="myPass" id="myPass" />
                        </div>

                        <div class="col-12">
                            <div v-if="errorCode === 'possible_attack' || errorCode === 'login_attempted_exceeded'">
                                <button class="btn btn-outline-secondary float-end" @click="redirectForgotPassword()">{{ $t('message.all_btn_reset_pwd') }}</button>
                            </div>
                            <div v-else-if="errorCode === 'invalid_password'">
                                <button class="btn btn-secondary" @click="redirectForgotPassword()">{{ $t('message.all_btn_reset_pwd') }}</button>
                                <div class="float-end">
                                    <button class="btn btn-secondary me-2" @click="reset">{{ $t('message.all_btn_clear') }}</button>
                                    <button class="btn btn-info text-white" :class="isSubmit ? 'disabled' : ''" @click="login">{{ $t('message.all_btn_login') }}</button>
                                </div>
                            </div>
                            <div v-else class="float-end">
                                <button class="btn btn-secondary me-2" @click="reset">{{ $t('message.all_btn_clear') }}</button>
                                <button class="btn btn-info text-white" :class="isSubmit ? 'disabled' : ''" @click="login">{{ $t('message.all_btn_login') }}</button>
                            </div>
                        </div>

                    </div>

                    <div v-if="stage === 3" class="row">
                        <div class="col-12 pb-3">
                            <div class="col-12 pb-2">{{ $t('message.login_desc_resend_lnk_activation') }}</div>
                        </div>

                        <div class="col-12 pb-3">
                            <input v-model="email" class="form-control" :placeholder="$t('message.all_lbl_email')">
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <div style="width: 200px; height: 100px">
                                <img :src="captchaImgResend" class="border bg-info" style="position:absolute; width: 200px; height: 100px" />
                                <button class="btn btn-secondary btn-sm mb-3" @click="getCaptchaResend" style="z-index: 10000; opacity: 0.5; float: right; margin-top: 68px">
                                    <Popper class="popperDark" arrow hover :content="$t('message.all_btn_refresh_captcha')">
                                        <fa icon="refresh" />
                                    </Popper>
                                </button>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 pb-3">
                            <input v-model="captchaResend" class="form-control" :placeholder="$t('message.all_lbl_captcha')">
                            
                        </div>
                        <div class="col-12">
                            {{ $t('message.login_desc_resend_act_email') }}
                        </div>
                        <div class="col-12 text-center">
                            <button type="button" class="btn btn-info text-white mt-4" @click="resendActivation">{{ $t('message.all_btn_resend_act_email') }}</button>
                        </div>
                    </div>

                    <div v-if="stage === 4" class="row">
                        <div class="col-12">
                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td colspan="2" class="text-danger">
                                            <div v-if="failedLoginAttempt >= maxLoginAttempt">
                                                <div class="mb-2">{{ $t('message.login_desc_fail_1') }}</div>
                                                <div>{{ $t('message.login_desc_fail_2') }}</div>
                                            </div>
                                            <div v-else>
                                                <div class="mb-2">{{ $t('message.login_desc_fail_left', {attemptLeft: maxLoginAttempt - failedLoginAttempt}) }}</div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr v-if="failedLoginAttempt < maxLoginAttempt">
                                        <td width="60px">
                                            <ImgAvatar :isUser="true" :id="username" displayName="" isVerified="false" isActive="false" class="fw-bold navbar-display-name" align="left" width="50" />
                                        </td>
                                        <td>
                                            <div>{{ $t('message.login_desc_is_you') }}</div>
                                            <div class="text-grey">{{ username }}</div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-if="failedLoginAttempt >= maxLoginAttempt" class="col-12 text-end">
                            <button class="btn btn-outline-secondary" @click="redirectForgotPassword()">{{ $t('message.all_btn_reset_pwd') }}</button>
                        </div>
                        <div v-else class="col-12 text-end">
                            <button class="btn btn-secondary me-2" @click="reset">{{ $t('message.all_btn_no') }}</button>
                            <button class="btn btn-info text-white" @click="reEnterPassword">{{ $t('message.all_btn_yes') }}</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <!-- Right column -->
            <div v-if="showUIUserPending === false" class="col-12 col-lg-5">

                <div class="border shadow-sm loginBoxSm">
                    <table width="100%" >
                        <tr>
                            <td width="20%"><fa icon="user" style="font-size: 25px"/></td>
                            <td>
                                <div>{{ $t('message.login_desc_not_subscriber_1') }}</div>
                                <div v-if="route.query.m === '1'">
                                     {{ $t('message.login_desc_not_subscriber_2a') }}
                                    <router-link :to="{ name: 'SignUp' }">
                                        <span class="greyLink"><u>{{ $t('message.login_desc_not_subscriber_2b') }}</u></span>
                                    </router-link>{{ $t('message.login_desc_not_subscriber_2c') }}
                                </div>
                                <div v-else>{{ $t('message.login_desc_not_subscriber_2a') }}
                                    <router-link :to="{ name: 'SignUp', query: route.query }">
                                        <span class="greyLink"><u>{{ $t('message.login_desc_not_subscriber_2b') }}</u></span>
                                    </router-link>{{ $t('message.login_desc_not_subscriber_2c') }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- <div class="shadow-sm whiteBox">
                    <div class="row">
                        <div class="col-16 mb-2" style="text-align: justify; text-justify: inter-word">
                            Signature can be forged physically or digitally (aka Picture Signature). It is important to sign a document with a validated email to ensure you are the actual signee. SignOn traces the signing process with full Audit Report.
                        </div>
                        <div class="col-16" style="text-align: justify; text-justify: inter-word">Sign up now and sign documents securely with peace of mind.</div>
                    </div>
                </div>
                <div class="shadow-sm blueBox border-top-0">
                    <table class="table table-borderless mb-0" border="0">
                        <tbody>
                            <tr>
                                <td>
                                    <div style="padding-top: 10px">
                                        <fa icon="signature" class="bg-light text-secondary" style="font-size: 30px; padding: 18px 14px; border-radius: 32px" />
                                    </div>
                                </td>
                                <td>
                                    <div class="mb-2 text-white"><span class="fw-bold">It's always free to sign. </span>Get yourself a free account today.</div>
                                    <div class="text-end">
                                        <button type="button" class="btn btn-secondary" @click="$router.push({ path: '/auth/signup' })">Sign Up</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->

                <div class="border shadow-sm loginBoxSm">
                    <table width="100%" >
                        <tr>
                            <td width="20%"><fa icon="key" style="font-size: 25px"/></td>
                            <td>
                                <div>{{ $t('message.login_desc_forgot_pwd') }}</div>
                                <div v-if="route.query.m === '1'">{{ $t('message.login_desc_click_to') }}
                                    <router-link :to="{ name: 'ForgotPassword' }">
                                        <span class="greyLink"><u>{{ $t('message.login_desc_reset_pwd_1a') }}</u></span>    
                                    </router-link>{{ $t('message.login_desc_reset_pwd_1b') }}
                                </div>
                                <div v-else>{{ $t('message.login_desc_click_to') }}
                                    <router-link :to="{ name: 'ForgotPassword', query: route.query }">
                                        <span class="greyLink"><u>{{ $t('message.login_desc_reset_pwd_1a') }}</u></span>    
                                    </router-link>{{ $t('message.login_desc_reset_pwd_1b') }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="border shadow-sm loginBoxSm">
                    <table width="100%" >
                        <tr>
                            <td width="20%"><fa icon="envelope" style="font-size: 25px"/></td>
                            <td>
                                <div>{{ $t('message.login_desc_not_rec_act') }}</div>
                                <div>{{ $t('message.login_desc_click_to') }}
                                <span class="isLink" @click="showResend"><u>{{ $t('message.login_desc_resend_email_1a') }}</u></span>{{ $t('message.login_desc_resend_email_1b') }}
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>

            <div v-if="isCookieEnable === false" class="col-12 text-grey">
                {{ $t('message.login_desc_cookies') }}
            </div>

        </div>
    </div>

    <!-- Modal - ChangeToManual --> 
    <div
        class="modal fade"
        id="mdlChangeToManual"
        tabindex="-1"
        aria-labelledby="ChgToManual"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">{{ $t('message.all_mdl_title_confirmation') }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            {{ $t('message.login_mdl_desc_switch_local') }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />{{ $t('message.all_btn_close') }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="convertToSystemMethod" data-bs-dismiss="modal">
                        <i class="fa-solid fa-check me-2"></i>{{ $t('message.all_btn_confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 

    <!-- Modal - ChangeToSSO --> 
    <div
        class="modal fade"
        id="mdlChangeToSSO"
        tabindex="-1"
        aria-labelledby="ChgToSSO"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered text-dark">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title" id="exampleModalLabel">{{ $t('message.all_mdl_title_confirmation') }}</h5>
                    <button type="button" class="btn-close"  data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            {{ $t('message.login_mdl_desc_switch_local') }}
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="justify-content: space-between">
                    <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">
                        <fa icon="xmark" class="me-2" />{{ $t('message.all_btn_close') }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="convertToSSOMethod" data-bs-dismiss="modal">
                        <i class="fa-solid fa-check me-2"></i>{{ $t('message.all_btn_confirm') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal End --> 
    
</template>

<script>
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { ref, inject, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores/store'
import { useI18n } from 'vue-i18n'  // use i18n in setup purpose
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import auth from '@/functions/auth'
import ImgAvatar from '@/components/ImgAvatar.vue'

const freeEmailDomains = require('free-email-domains')

export default {
    name: 'Login',
    components: { TopNavigation, Alert, Popper, ImgAvatar },
    setup () {
        const alert = ref([])
        const axios = inject('axios')
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const authFunc = auth
        const store = useStore()
        const { getUserId } = useStore()
        const { t } = useI18n() // use i18n in setup purpose

        const stage = ref(1) // default:1 - username, 2 - password, 3 - resend activation link, 4 - invalid_apssword and show user avatar to confirm
        const username = ref(null)
        const refId = ref(null)
        const password = ref(null)
        const urlSignUp = ref('/auth/signup')
        const displayName = ref('')
        const userId = ref('')
        const lastLogin = ref('')
        const email = ref(null)
        const isSubmit = ref(false)  // true - submitted username successfully, pending for password

        const captchaImgResend = ref(null)
        const refIdResend = ref(null)
        const captchaResend = ref(null)
        const myPass = ref(null) // onfocus password purpose
        const showUIUserPending = ref(false)

        const validSession = ref(null)
        const user = ref(null)  // logged in user data
        const isCookieEnable = ref(null) // chk if cookie / web storage enable
        const errorCode = ref('') //error code to indicate change login method
        const curLoginMethod = ref('system') // Indicate current login  method (system/oauth)
        const isUsernameFocus = ref(false)
        const isOauthFocus = ref(false)

        const maxLoginAttempt = ref(3)
        const failedLoginAttempt = ref(1) //ynlang 0

        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        
        const closeAlert = (index) => {
            alert.value.splice(index, 1)

        }

        const convertToSystemMethod = () => {}

        const convertToSSOMethod = () => {}

        const redirectForgotPassword = async () => {
            console.info('username', username.value)

            userId.value = computed({
                get: () => store.getUserId.value,
                set: store.updUserId(username.value),
            })
            userId.value = store.getUserId.value 

            router.push({ path: '/auth/forgotpassword' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
        }

        const reEnterPassword = async () => {
            userId.value = computed({
                get: () => store.getUserId.value,
                set: store.updUserId(username.value),
            })
            userId.value = store.getUserId.value 

            password.value = null
            stage.value = 2
        }

        const redirectLogin = () => {
            router.push({ path: '/auth/login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
        }

        const LogoutLogin = async () => {
            const isLogout = await authFunc.logout()

            if (isLogout[0] === '1') {
                router.push({ path: '/auth/login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })
                
            } else {
                func.addLog('login', 'LogoutLogin', isLogout)
                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: t('message.all_alr_logout_failed_session')
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: t('message.all_alr_logout_failed_offline')
                    })

                } else {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: t('message.all_alr_logout_failed') + isLogout[1]
                    })

                }

                // auto redirect to login page when logout failed
                router.push({ path: '/auth/login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })

            }

        }

        const nextStage = async () => {
            // console.info('- - TEST', t('message.support_title_desc'))

            if (username.value === null || username.value === '') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: t('message.all_alr_vld_username')
                })

            } else {
                const p = { "username": username.value }

                axios.post('/auth/signinreq', p, config)
                .then((res) => {

                    if (res.data.status === 1) {
                        errorCode.value = ''
                        stage.value = 2
                        refId.value = res.data.data.refId
                        setTimeout(() => myPass.value.focus(), 500)

                    } else {
                        func.addLog('login', 'nextStage', res)
                        if (res && res.data !== null && res.data !== undefined) {
                            errorCode.value = res.data.message
                            console.info('login', res.data.message)

                            if (res.data.message === 'user_not_found') {
                                alert.value.push({
                                    class: "info",
                                    title: "INFO",
                                    message: t('message.login_alr_user_not_found') +' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'possible_attack' || res.data.message === 'login_attempted_exceeded') {
                                
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.login_alr_acc_locked') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'invalid_open_id') {
                                // 10014

                                // if (getEmailDomain(username.value) === 'signon.my' || getEmailDomain(username.value) === 'gmail.com') {
                                // if (getEmailDomain(username.value) === 'signon.my') {
                                    // errorCode.value = res.data.message
                                    curLoginMethod.value = 'system'
                                    isOauthFocus.value = true

                                // } else {
                                    alert.value.push({
                                        class: "danger",
                                        title: "ERROR",
                                        message: t('message.login_alr_register_sso', {username: username.value})
                                    })
                                // }

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }

                        }
                    }
                })
                .catch((error) => {
                    func.addLog('login', 'nextStage - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })
            }

        }

        const login = async () => {
            isSubmit.value = true

            const p = {
                "refId" : refId.value,
                "password": password.value
            }

            axios.post('/auth/signin', p, config)
                .then((res) => {

                    if (res.data.status === 1) {
                        localStorage.setItem('userId', res.data.data.userId)
                        localStorage.setItem('wlId', res.data.data.wlId)
                        localStorage.setItem('entityId', res.data.data.entityId)
                        localStorage.setItem('sessionId', res.data.data.sessionId)

                        displayName.value = computed({
                            get: () => store.getDisplayName.value,
                            set: store.updDisplayName(res.data.data.displayName),
                        })
                        displayName.value = store.getDisplayName.value 

                        userId.value = computed({
                            get: () => store.getUserId.value,
                            set: store.updUserId(res.data.data.userId),
                        })
                        userId.value = store.getUserId.value 

                        lastLogin.value = computed({
                            get: () => store.getUserLastLogin.value,
                            set: store.updUserLastLogin(res.data.data.dateLastLogin),
                        })
                        lastLogin.value = store.getUserLastLogin.value

                        let redirectPath = '/dashboard'

                        if (route.query.redirect) {
                            route.query.fr = res.data.data.displayName
                            if (route.query.redirect === 'InfoProj') {
                                redirectPath = '/p/' + route.query.p

                            } else if (route.query.redirect === 'ViewProjDoc') {
                                redirectPath = '/p/' + route.query.p + '/' + route.query.d

                            } else if (route.query.redirect === 'ViewDocInfo') {
                                redirectPath = '/d/' + route.query.d

                            } else if (route.query.redirect === 'UploadDocNew') {
                                redirectPath = '/uploadDoc'

                            } else if (route.query.redirect === 'UploadDoc') {
                                redirectPath = '/uploadDoc/' + route.query.p

                            } else if (route.query.redirect === 'ProjectOverview') {
                                redirectPath = '/project/' + route.query.p

                            } else if (route.query.redirect === 'ProjectList') {
                                redirectPath = '/project'

                            } else if (route.query.redirect === 'VerifyDoc') {
                                redirectPath = '/doc/verify'

                            } else if (route.query.redirect === 'PdfView') {
                                redirectPath = '/pdf/view/' + route.query.p + '/' + route.query.d + '/upload'

                            } else if (route.query.redirect === 'Signatory') {
                                redirectPath = '/signatory'

                            } else if (route.query.redirect === 'PdfDetail') {
                                redirectPath = '/pdf/detail'

                            } else if (route.query.redirect === 'PdfDetailSignee') {
                                redirectPath = '/pdf/detailSignee'

                            } else if (route.query.redirect === 'PdfDetailSignee2') {
                                redirectPath = '/pdf/detailSignee2'

                            } else if (route.query.redirect === 'AddSignee') {
                                redirectPath = '/addSignee'

                            } else if (route.query.redirect === 'ReviewInvite') {
                                redirectPath = '/reviewInvite'

                            } else if (route.query.redirect === 'ManageAccount') {
                                redirectPath = '/auth/manageaccount'

                            } else {
                                redirectPath = '/dashboard'

                            }

                        }

                        setTimeout(() => {
                            if (redirectPath === '/uploadDoc') {
                                router.push({ name: 'UploadDocNew', query: { l: res.data.data.dateLastLogin, a: res.data.data.dateLastActive, fl: res.data.data.isFirstTimeLogin } })

                            } else {
                                router.push({ path: redirectPath, query: { l: res.data.data.dateLastLogin, a: res.data.data.dateLastActive, fl: res.data.data.isFirstTimeLogin } })
                            
                            }
                        }, 2000)

                    } else {
                        isSubmit.value = false

                        func.addLog('login', 'login', res)
                        if (res && res.data !== null && res.data !== undefined) {

                            errorCode.value = res.data.message
                            if (res.data.message === 'user_not_found' || res.data.message === 'ip_not_match') {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.login_alr_not_found') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'possible_attack' || res.data.message === 'login_attempted_exceeded') {
                                // show error in UI instead of alert

                            } else if (res.data.message === 'refId_expired') {
                                reset()
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.login_alr_timeout') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_expired') {
                                stage.value = 3
                                getCaptchaResend()

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.login_alr_expired') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'invalid_password') {
                                stage.value = 4
                                maxLoginAttempt.value = res.data.data.maxLoginAttempt
                                failedLoginAttempt.value = res.data.data.failedLoginAttempt
                                /* alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: 'The password does not match with your username, please try to re-key in your password or you may request to reset your password here. (' + res.data.status + ')'
                                }) */

                            } else if (res.data.message === 'user_pending') {
                                showUIUserPending.value = true

                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })

                            }
                        }

                    }
                })
                .catch((error) => {
                    isSubmit.value = false
                    func.addLog('login', 'login - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const logout = async () => {
            const isLogout = await authFunc.logout()

            if (isLogout[0] === '1') {
                //ORIG: router.push('/auth/login')
                router.push({ name: 'Login', query: { m: '1' } })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })

            } else {
                func.addLog('login', 'logout', isLogout)
                
                if (isLogout[1] === 'no_session') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: t('message.login_alr_no_session')
                    })

                } else if (isLogout[1] === 'user_not_online') {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: t('message.login_alr_offline')
                    })

                } else {
                    alert.value.push({
                        class: "danger",
                        title: "FAILED",
                        message: 'Logout failed. ' + isLogout[1]
                    })

                }

                // auto redirect to login page when logout failed
                // router.push('/auth/login')
                router.push({ name: 'Login' })
                .then(() => {
                    router.go() // refresh the page so that can capture sessionId in store
                })

            }

        } 

        const loginGoogle = () => {
            window.location.href = axios.defaults.baseURL + 'auth/signin/oauth/google'
        }

        const reset = () => {
            stage.value = 1
            username.value = null
            refId.value = null
            password.value = null
            isSubmit.value = false

            errorCode.value = ''

        }

        const showResend = () => {
            showUIUserPending.value = false
            getCaptchaResend()
            stage.value = 3

        }

        const getCaptchaResend = () => {
            axios.get('/reference/captcha/RACTIVATE')
                .then((res) => {

                    if (res.data.status === 1) {
                        captchaImgResend.value = res.data.data.captcha.image
                        refIdResend.value = res.data.data.refId

                    } else {
                        func.addLog('login', 'getCaptchaResend', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })

                        }

                    }

                })
                .catch((error) => {
                    func.addLog('login', 'getCaptchaResend - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const resendActivation = () => {

            const p = {
                username: email.value,
                refId: refIdResend.value,
                captcha: captchaResend.value,
                frontendUrl: '/auth/activate'
            } 

            axios.post('/auth/signup/resend', p)
                .then((res) => {

                    if (res.data.status === 1) {
                        alert.value.push({
                            class: "success",
                            title: "SUCCESS",
                            message: t('message.all_alr_act_sent')
                        })

                    } else {
                        func.addLog('login', 'resendActivation', res)

                        if (res && res.data !== null && res.data !== undefined) {
                            if (res.data.message === 'captcha_not_match' || res.data.message === 'refId_not_found' || res.data.message === 'ip_not_match') {
                                getCaptchaResend()
                                captchaResend.value = ''

                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: t('message.all_alr_captcha_unmatch') + ' (' + res.data.status + ')'
                                })

                            } else if (res.data.message === 'user_was_activated') {
                                alert.value.push({
                                    class: "info",
                                    title: "INFO",
                                    message: t('message.login_alr_activated')
                                })
                                
                            } else {
                                alert.value.push({
                                    class: "danger",
                                    title: "ERROR",
                                    message: func.getError(res.data)
                                })
                            }

                        }

                    }
                })
                .catch((error) => {
                    func.addLog('login', 'resendActivation - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)

                })

        }

        const chkSession = async () => {
            try {
                const res = await axios.get('/auth/session')

                if( res.data.status === 1) {
                    validSession.value = true
                    user.value = res.data.data

                } else {

                    validSession.value = false

                    if (res && res.data !== null && res.data !== undefined) {
                        if (res.data.message !== 'no_session' ) {
                            func.addLog('login', 'chkSession', res)

                            alert.value.push({
                                class: "danger",
                                title: "ERROR",
                                message: func.getError(res.data)
                            })
                        }

                    }
                }

            } catch (error) {
                validSession.value = false
                if (error.code === 'undefined' || error.code === undefined) {
                } else {
                    func.addLog('login', 'chkSession - catch', error.code + ' | ' +error.status + ' | ' + error.message + ' | ' + error.data +  ' | ' + error)
                }
            }

        }

        const getEmailDomain = (email) => {
            return email.split('@')[1]
        }

        onMounted(async () => {

            isCookieEnable.value = (navigator.cookieEnabled === true ? true : false)
            chkSession()

            if (route.query.u && route.query.u.length > 0) {
                username.value = route.query.u

            } else if (!(getUserId === null || getUserId === '')) {
                username.value = getUserId.value

            }

            if (route.query.e === 'timeout' || route.query.e === 'ns') {
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: t('message.all_alr_timeout')
                })

            }

            if (route.query.code === '10014' || route.query.code === 10014) {
                curLoginMethod.value = 'oauth'
                username.value = route.query.email

                /* const emailDomain = await getEmailDomain(username.value)

                // if (emailDomain === 'signon.my' || emailDomain === 'genusis.com') {
                if (emailDomain === 'signon.my') {
                    errorCode.value = 'invalid_open_id' // route.query.code (aka 10014)
                    isUsernameFocus.value = true

               } else {
                    username.value = null

                    alert.value.push({
                        class: "danger",
                        title: "ERROR",
                        message: 'The email address '+ route.query.email +' is already registered for manual local login. Please log in using manual local method or contact support for assistance.'
                    })
                }  */
                errorCode.value = 'invalid_open_id' // route.query.code (aka 10014)
                isUsernameFocus.value = true

            } else if (route.query.code === '10046' || route.query.code === 10046) {
                username.value = null
                
                alert.value.push({
                    class: "danger",
                    title: "ERROR",
                    message: t('message.login_alr_no_google_permission')
                })
                
            } else if (route.query.code === '10050' || route.query.code === 10050) {
                // user_id_mismatched - Chang sign-in method to google but using different google email
                router.push({ path: '/auth/preference', query: {ct: 'password', code: route.query.code, email: route.query.email} })
               
            }

            if (route.query.m === '1') {
                // Manually logout
                alert.value.push({
                    class: "info",
                    title: "INFO",
                    message: t('message.all_alr_logout')
                })
            }
        })
        

        return { 
            alert, route, stage, username, refId, password, urlSignUp, 
            nextStage, login, reset, closeAlert, func, authFunc, loginGoogle, email, 
            getCaptchaResend, captchaImgResend, refIdResend, captchaResend, resendActivation, isSubmit, myPass, 
            showResend, showUIUserPending, isCookieEnable, errorCode, curLoginMethod, isUsernameFocus, isOauthFocus, redirectLogin, 
            validSession, user, LogoutLogin, logout, convertToSystemMethod, convertToSSOMethod, reEnterPassword,
            maxLoginAttempt, failedLoginAttempt, redirectForgotPassword
        }
    }
}
</script>

<style>
.loginBox {
  border-radius: 10px;
  /* padding: 35px 40px; */
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
}

.loginBoxSm {
  border-radius: 10px;
  /* padding: 35px 40px; */
  padding: 25px;
  margin-bottom: 25px;
  margin-left: 2px;
  margin-right: 2px;
  background-color: var(--auth-box-bg);
}

.loginContainer {
  max-width: 850px;
  margin: 105px auto 20px auto;
  border-radius: 10px;
}

@media (max-width: 575.98px) {
    .loginBox {
      border-radius: 10px;
    }

    .loginBoxSm {
      border-radius: 10px;
      background-color: var(--auth-box-bg);
    }

    .loginContainer {
      width: 95%;
      margin: 105px auto 20px auto;
    }
}

.whiteBox {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 25px;
    margin-left: 2px;
    margin-right: 2px;
    border-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
}

.blueBox {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 25px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 25px;
    border-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    background-color: var(--bs-primary);
}
</style>